import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import LeadForm from "../components/form"
//import Contactform from "../components/contact"

const RateRequestPage = () => (
  <Layout>
    <Seo title="MediGap Rate Request" />

    <main>
      <iframe
        src="https://insurancetoolkits.com/medsupp/lite-form/?token=3imyP8NN4N_5G17A12T8BTFLzToSUbsrSA4MqK_W"
        style={{ border: "none" }}
        width="100%"
        height={800}
        title="Medigap Rate Request"
      ></iframe>
      {/* <iframe
        style="border: none; height: 800px; width: 100%;"
        src="https://insurancetoolkits.com/medsupp/lite-form/?token=3imyP8NN4N_5G17A12T8BTFLzToSUbsrSA4MqK_W"
      ></iframe> */}
    </main>
  </Layout>
)

export default RateRequestPage
